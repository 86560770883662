import { ApiClient } from "@equidefi/portals/clients";
import { ApiInstance } from "./api";

class InvestmentClient extends ApiClient {
  static async acceptTos(id) {
    const response = await ApiInstance.post(`/investment/${id}/tos`);
    return response.data;
  }

  /**
   * @param {string} id
   * @param {string} stage
   * @param {object} data
   * @return {Promise<object>}
   */
  static async updateStage(id, stage, data = {}) {
    const response = await ApiInstance.patch(
      `/v2/investments/${id}/stage/${stage}`,
      data
    );
    return response.data;
  }

  static async resetInquiry(investmentId, inquiryId) {
    await ApiInstance.delete(
      `/v2/investments/${investmentId}/inquiry/${inquiryId}`
    );
  }

  static async resumeInquiry(investmentId, inquiryId) {
    await ApiInstance.post(
      `/v2/investments/${investmentId}/inquiry/${inquiryId}`
    );
  }

  static async subscription(
    investmentId,
    amount,
    quantity,
    annualIncome,
    accredited = null
  ) {
    const response = await ApiInstance.post(
      `/v2/investments/${investmentId}/subscription`,
      {
        amount: amount,
        quantity: quantity,
        annual_income: annualIncome,
        accredited,
      }
    );

    return response.data;
  }

  static async dispatchEvent(investmentId, payload) {
    const response = await ApiInstance.post(
      `/v2/investments/${investmentId}/event`,
      payload
    );

    return response.data;
  }
}

export default InvestmentClient;
