import {
  useInitiatePaymentMutation,
  useInvestment,
  useSetupManualInvestmentPayment,
} from "../../../hooks/useInvestments";
import { PaymentAccordion } from "../../workflow/payment/PaymentAccordion";
import { useNavigate, useParams } from "react-router";
import { useMemo, useState } from "react";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Flex,
} from "@chakra-ui/react";
import { useOffering, useOfferingSettings } from "../../../hooks/useOfferings";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import { stripeAccountPromise, stripePromise } from "../../../constants/stripe";
import { MAXIMUM_STRIPE_AMOUNT } from "@equidefi/shared/constants/payments";
import { PaymentStepContext } from "../../workflow/payment/context";
import { WorkflowContainer } from "../../workflow/WorkflowContainer";
import { CurrencyFormatter } from "@equidefi/portals/helpers/format";
import { Text } from "@equidefi/ui";
import { PAYMENT_CATEGORY_MAP } from "../../../constants/payments";
import { useOfferingAnalytics } from "../../../hooks/useOfferingAnalytics";

const VaultPayment = () => {
  const { id } = useParams();

  const { data: investment } = useInvestment(id, {
    refetchOnMount: true,
  });
  const { data: offering } = useOffering(investment?.offering?.id);
  const navigate = useNavigate();
  const analytics = useOfferingAnalytics(offering);

  const { data: settings } = useOfferingSettings(investment?.offering?.id);

  const setupManualPayment = useSetupManualInvestmentPayment(id);

  const settingsMap = useMemo(() => {
    const settingValue = (key) =>
      settings?.find((s) => s.id === key)?.value ?? "";

    return {
      bank_name: settingValue("payment_bank_name"),
      account_name: settingValue("payment_account_name"),
      account_number: settingValue("payment_account_number"),
      routing_number: settingValue("payment_routing_number"),
      transfer_agent_name: settingValue("transfer_agent_company"),
      wireInstructions: settingValue("manual_payment_wire_instructions"),
      checkInstructions: settingValue("manual_payment_check_instructions"),
    };
  }, [settings]);

  const createPayment = useInitiatePaymentMutation(id);

  useDocumentTitle([
    "Payment",
    investment?.offering?.name,
    investment?.issuer?.name,
  ]);

  const [paymentType, setPaymentType] = useState(null);

  const [clientSecret, setClientSecret] = useState("");
  const [paymentIntentId, setPaymentIntentId] = useState("");
  const [stripe, setStripe] = useState(null);

  const handleSectionChange = (index) => {
    const type = PAYMENT_CATEGORY_MAP[index];
    setPaymentType(type);
    if (type === "STRIPE") setupStripePayment();
  };

  const setupStripePayment = async () => {
    if (clientSecret) return null;

    try {
      const clientSecretData = await createPayment.mutateAsync();

      if (offering.stripe_account_id) {
        setStripe(stripeAccountPromise(offering.stripe_account_id));
      } else {
        setStripe(stripePromise);
      }
      setClientSecret(clientSecretData.clientSecret);
      setPaymentIntentId(clientSecretData.id);
    } catch (e) {
      console.error(e);
    }
  };

  const handlePayment = async () => {
    analytics.track("payment", { type: "manual" });

    try {
      await setupManualPayment.mutateAsync({
        category: "MANUAL",
        payment_type: paymentType.toLowerCase(),
      });

      navigate(`/vault/investment/${investment.id}`);
    } catch (e) {
      console.error(e);
    }
  };

  const supportsStripePayment = investment?.amount <= MAXIMUM_STRIPE_AMOUNT;
  return (
    <PaymentStepContext.Provider
      value={{
        paymentIntentId,
        clientSecret,
      }}
    >
      <WorkflowContainer title="Payment">
        <Box>
          <Text textStyle="context" mb={0} fontWeight="bold">
            {investment?.issuer?.name}
          </Text>
          <Flex justify="space-between">
            <Text textStyle="context">
              {investment?.quantity} shares x{" "}
              {CurrencyFormatter.format(investment?.price)}
            </Text>

            <Text textStyle="body2">
              {CurrencyFormatter.format(investment?.amount)}
            </Text>
          </Flex>
          <Alert
            status="warning"
            borderRadius="lg"
            border="1px solid"
            borderColor="equidefi.yellow"
            boxShadow="sm"
            mb={2}
          >
            <AlertIcon alignSelf="flex-start" />
            <AlertDescription>
              <Text textStyle="body2" fontWeight="bold">
                Please read carefully
              </Text>
              <Text textStyle="body2">
                <strong>YOU MUST BE AN ACCREDITED INVESTOR</strong>, which means
                you will be able to provide proof showing $200,000 in income in
                2022 and 2023 for individuals or $300,000 of joint income or a
                copy of a bank or brokerage statement showing $1 million in
                assets net of liabilities and exclusive of residence, or a pro
                letter.
              </Text>
              <Text textStyle="bold" mb={0} pb={0}>
                <strong>Refunds</strong>
              </Text>
              <Text textStyle="body2">
                If you do not meet the requirements for this offering, you will
                be notified on how to receive a refund. Please note that your
                refund can take up to seven days to be processed.
              </Text>
              <Text textStyle="bold" mb={0} pb={0}>
                <strong>Investment Review</strong>
              </Text>
              <Text textStyle="body2">
                Kindly note that it can take 7-10 business days to review your
                paperwork for final approval. Please be patient through this
                process and we will reach out to you directly should we need to
                be in touch with you.
              </Text>
            </AlertDescription>
          </Alert>
          <Text textStyle="context" mt={0} mb={4}>
            CC/ACH fees are paid for by the company
          </Text>
        </Box>

        <PaymentAccordion
          onSubmitManualPayment={handlePayment}
          supportsStripePayment={supportsStripePayment}
          onSectionChange={handleSectionChange}
          stripe={stripe}
          clientSecret={clientSecret}
          isManualPaymentLoading={setupManualPayment.isLoading}
          paymentSettings={settingsMap}
          investment={investment}
          offering={offering}
          redirectUrl={`/vault/investment/${investment.id}`}
        />
      </WorkflowContainer>
    </PaymentStepContext.Provider>
  );
};

export default VaultPayment;
