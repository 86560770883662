import { useMutation, useQuery } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";

import OfferingClient from "@equidefi/portals/clients/OfferingClient";
import { useApi } from "@equidefi/portals/hooks/useApi";

/**
 * @param {string} offeringId
 * @param {import("@tanstack/react-query").QueryObserverOptions} options
 */
export const useOffering = (offeringId, options = {}) => {
  const offeringApi = useApi(OfferingClient);

  return useQuery(
    ["offering", offeringId, "legacy"],
    () => offeringApi.legacyFind(offeringId),
    {
      enabled: !!offeringId,
      ...options,
    }
  );
};

/**
 * @param {string} idOrSlug
 * @param {import("@tanstack/react-query").QueryObserverOptions} options
 */
export const useOpenOffering = (idOrSlug, options = {}) => {
  const offeringApi = useApi(OfferingClient);

  return useQuery(
    ["offering", idOrSlug, "v2"],
    () => offeringApi.getById(idOrSlug),
    {
      enabled: !!idOrSlug,
      ...options,
    }
  );
};

export const useOfferingSettings = (offeringId, options = {}) => {
  const offeringApi = useApi(OfferingClient);
  const toast = useToast();
  return useQuery(
    ["offering", offeringId, "settings"],
    () => offeringApi.legacyGetSettings(offeringId),
    {
      onError: () => {
        toast({
          status: "error",
          description: "Sorry! Offering could not be found",
        });
      },
      enabled: !!offeringId,
      ...options,
    }
  );
};

/**
 * @param {string} id
 * @param {import("@tanstack/react-query").QueryObserverOptions} options
 */
export const useOfferingBrokers = (id, options = {}) => {
  const offeringApi = useApi(OfferingClient);

  return useQuery(
    ["offering", id, "brokers"],
    () => offeringApi.brokers(id),
    options
  );
};
