import { Center, ListItem, Spinner, UnorderedList } from "@chakra-ui/react";
import React, { useContext, useMemo } from "react";
import { Link, useNavigate } from "react-router";

import { useInvestmentInquiry } from "@equidefi/portals/hooks/useInvestments";
import { Icon } from "@equidefi/ui/icon";

import { InvestmentStatusView } from "../../../components/offerings/StatusView";
import { WorkflowButton } from "../../../components/workflow/WorkflowButton";
import { useEmbedInvestmentInquiry } from "../../../hooks/useInquiries";
import useWorkflowPosition from "../../../hooks/useWorkflowPosition";
import { WorkflowContainer } from "../WorkflowContainer";
import { WorkflowContext } from "../context";

const InquiryStatusView = ({
  inquiryStatus,
  isLoading: _isLoading,
  kyckyb,
}) => {
  const { investment, offering } = useContext(WorkflowContext);
  const navigate = useNavigate();
  const { onStart, isLoading: isEmbedLoading } = useEmbedInvestmentInquiry(
    investment?.id,
    {
      onComplete: () => {
        navigate(`/offerings/${offering.slug}/${investment.id}/agreement`);
      },
    }
  );

  const isLoading = useMemo(
    () => isEmbedLoading || _isLoading,
    [_isLoading, isEmbedLoading]
  );

  if (!inquiryStatus || !investment) {
    return null;
  }

  if (inquiryStatus === "unknown") {
    return (
      <>
        <InvestmentStatusView title="Verify your identity">
          <UnorderedList p="0" mt="4">
            <ListItem mt>
              Please have your driver&apos;s license or government issued ID
              ready (e.g. passport).
            </ListItem>
            <ListItem>
              Your front-facing camera may be used to validate your ID.
            </ListItem>
            <ListItem>
              If you don&apos;t have a camera on this device, you can complete
              the verification on your mobile device.
            </ListItem>
          </UnorderedList>
        </InvestmentStatusView>
        <WorkflowButton isLoading={isLoading} as={Link} onClick={onStart}>
          Start
        </WorkflowButton>
      </>
    );
  }

  if (inquiryStatus === "approved") {
    return (
      <>
        <InvestmentStatusView title="Approved!" icon={Icon.CheckCircle}>
          Well done! It looks like your{" "}
          {kyckyb === "KYB" ? "business verification" : "individual identity"}{" "}
          check was approved. You can continue to proceed with our investment.
        </InvestmentStatusView>
        <WorkflowButton
          isLoading={isLoading}
          as={Link}
          to={`/offerings/${offering.slug}/${investment.id}/agreement`}
        >
          Continue
        </WorkflowButton>
      </>
    );
  }

  if (inquiryStatus === "completed") {
    if (kyckyb === "KYB")
      return (
        <>
          <InvestmentStatusView title="Business verification started...">
            You have been sent an email with a request to complete your
            individual identity check. Your business verification has been
            started, and you will be notified by email once your business
            verification check has been reviewed.
          </InvestmentStatusView>
          <WorkflowButton
            isLoading={isLoading}
            as={Link}
            to={`/offerings/${offering.slug}/${investment.id}/agreement`}
          >
            Continue
          </WorkflowButton>
        </>
      );
    if (kyckyb === "KYC")
      return (
        <>
          <InvestmentStatusView title="Processing...">
            Your identity check has been completed, but is still processing.
            Please standby...
          </InvestmentStatusView>
          <Center>
            <Spinner />
          </Center>
        </>
      );
  }

  if (inquiryStatus === "created" || inquiryStatus === "pending") {
    return (
      <>
        <InvestmentStatusView
          title={`${
            kyckyb === "KYB" ? "Business verification" : "Identity check"
          } started...`}
        >
          Your {kyckyb === "KYB" ? "business verification" : "identity check"}{" "}
          has been started but has not been completed yet, please click the link
          below to resume.
        </InvestmentStatusView>
        <WorkflowButton isLoading={isLoading} onClick={onStart}>
          Resume
        </WorkflowButton>
      </>
    );
  }

  if (inquiryStatus === "expired" || inquiryStatus === "resumed") {
    return (
      <>
        <InvestmentStatusView
          title={`${
            kyckyb === "KYB" ? "Business verification" : "Identity verification"
          } expired`}
          centered
          muted
        >
          Your{" "}
          {kyckyb === "KYB" ? "business verification" : "identity verification"}{" "}
          has expired, please click the link below to resume.
        </InvestmentStatusView>
        <WorkflowButton isLoading={isLoading} onClick={onStart}>
          Resume
        </WorkflowButton>
      </>
    );
  }

  if (inquiryStatus === "declined") {
    return (
      <InvestmentStatusView title="Sorry!" icon={Icon.ShieldOff}>
        It looks like your{" "}
        {kyckyb === "KYB" ? "business verification" : "identity verification"}{" "}
        was declined. If you believe this to be in error, please contact the
        issuer of the offering.
      </InvestmentStatusView>
    );
  }

  if (inquiryStatus === "needs_review") {
    return (
      <InvestmentStatusView title="Under Review" icon={Icon.Activity}>
        It looks like your{" "}
        {kyckyb === "KYB" ? "business verification" : "identity verification"}{" "}
        was automatically marked for review. The issuer will review your
        information. You will receive an email when the issuer has accepted or
        declined your information.
      </InvestmentStatusView>
    );
  }
  return null;
};

export const WorkflowKYC = () => {
  const { investment } = useContext(WorkflowContext);
  useWorkflowPosition(6);

  const { data: inquiry, isFetching } = useInvestmentInquiry(investment?.id, {
    refetchInterval: 15_000,
    refetchIntervalInBackground: true,
  });

  const kyckyb = investment?.entity_type === "ENTITY" ? "KYB" : "KYC";

  const inquiryStatus = useMemo(
    () => inquiry?.status ?? "unknown",
    [inquiry?.status]
  );

  return (
    <WorkflowContainer>
      <InquiryStatusView
        inquiryStatus={inquiryStatus}
        investment={investment}
        isLoading={isFetching}
        kyckyb={kyckyb}
      />
    </WorkflowContainer>
  );
};
