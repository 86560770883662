import React, {
  createContext,
  FC,
  useState,
  useEffect,
  PropsWithChildren,
} from "react";
import UserToken from "../clients/UserToken";

type AuthContextType = {
  token?: string | null;
  isLoggedIn: boolean;
  setToken: (value: string) => void;
  clearToken: () => void;
};

export const AuthContext = createContext<AuthContextType>({
  isLoggedIn: false,
  setToken: () => {},
  clearToken: () => {},
});

const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(UserToken.valid());
  const [_token, _setToken] = useState(UserToken.token());

  useEffect(() => {
    if (!isLoggedIn && _token) clearToken();
  }, []);

  const clearToken = () => {
    _setToken(null);
    UserToken.clear();
    setIsLoggedIn(false);
  };

  const setToken = (value: string) => {
    const isValid = UserToken.valid(value);
    if (!isValid) return clearToken();

    _setToken(value);
    UserToken.setToken(value);
    setIsLoggedIn(true);
  };

  return (
    <AuthContext.Provider
      value={{
        token: _token,
        isLoggedIn,
        clearToken,
        setToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
