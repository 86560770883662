import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useLocation, useNavigate } from "react-router";

import { Button, useToast } from "@chakra-ui/react";
import { BackButton, Heading, Input, Text } from "@equidefi/ui";

import { ResendMfaLink } from "@equidefi/portals/components/ResendMfaLink";
import
  {
    useLogin,
    useMFACode,
  } from "@equidefi/portals/hooks/useAuthentication";

import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import LoginContainer from "./LoginContainer";

const MFA = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const submitMFA = useMFACode();
  const authFunction = useLogin();

  useDocumentTitle("Multi-Factor Authentication");

  const [mfaCode, setMfaCode] = useState("");
  const [spinner, showSpinner] = useState(false);

  const toast = useToast();

  const handleLogin = async (e) => {
    e.preventDefault();
    showSpinner(true);

    try {
      await submitMFA.mutateAsync({
        email: location?.state.email,
        mfa_code: mfaCode,
      });
      const redirectURL = location?.state?.redirectURL ?? "/vault/dashboard";
      navigate(redirectURL);
    } catch (e) {
      console.error(e);
      toast({
        status: "error",
        description: "Incorrect code, please try again",
      });
    } finally {
      showSpinner(false);
    }
  };

  useEffect(() => {
    if (
      location.state &&
      (!location.state?.password || !location.state?.email)
    ) {
      toast({
        status: "error",
        description: "Something went wrong. Please login again.",
      });
      navigate("/");
    }
  }, [location, navigate]);

  return (
    <LoginContainer>
      <form onSubmit={handleLogin} style={{ width: "100%" }}>
        <Heading color="white" fontWeight="bold" m="0" textStyle="h3">
          Two Factor Authentication
        </Heading>
        <Text color="white" mb="5" textStyle="body2">
          Your account is protected with two-factor authentication. We've sent
          you an email. Please enter the code below.
        </Text>
        <ResendMfaLink
          authFunction={authFunction}
          color="white"
          state={location.state}
        />
        <Input
          as={NumberFormat}
          name="mfa_code"
          format="######"
          mask="_"
          placeholder="0 0 0 0 0 0"
          value={mfaCode}
          onValueChange={({ value }) => setMfaCode(value)}
          autoComplete="off"
          h="12"
          textAlign="center"
          mb="5"
          mt="2"
        />

        <BackButton
          color="white"
          textAlign="center"
          text="Back to login"
          onClick={() => navigate("/")}
        />

        <Button
          isDisabled={mfaCode.length !== 6}
          h="12"
          w="full"
          type="submit"
          isLoading={spinner}
        >
          Continue
        </Button>
      </form>
    </LoginContainer>
  );
};

export default MFA;
