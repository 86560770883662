import { Box, Flex, IconButton, Image, VStack } from "@chakra-ui/react";
import { useLocation } from "react-router";
import { Icon } from "@equidefi/ui/icon";
import EquidefiLogo from "../../images/logo-white.png";
import {
  IssuerInfo,
  NavMenu,
  UserGreeting,
  WorkflowProgress,
} from "./components";
import { useAuth } from "@equidefi/portals/hooks/useAuth";

export const NavDesktopView = ({ position, offering, logout, menu }) => {
  const MenuIcon = menu.isOpen ? Icon.X : Icon.Menu;
  const { pathname } = useLocation();
  const isAdditionalSignerPage = pathname.includes("/investment/signer");
  const { isLoggedIn } = useAuth();

  return (
    <Flex
      position="fixed"
      direction="column"
      w="400px"
      zIndex="2"
      color="white"
      p="10"
      bgColor="primary"
      minH="full"
      align="start"
      justify="space-around"
    >
      {position === 1 && !isLoggedIn ? (
        <>
          <IssuerInfo offering={offering} isLargeScreen />
        </>
      ) : (
        <>
          {offering && position ? (
            <IconButton
              aria-label={menu.isOpen ? "Hide Menu" : "Open Menu"}
              icon={<MenuIcon size="2em" />}
              variant="unstyled"
              onClick={menu.onToggle}
            />
          ) : (
            <Image
              src={EquidefiLogo}
              alt="EquiDeFi"
              maxW="250px"
              alignSelf="center"
              mt={3}
            />
          )}
          {!offering || menu.isOpen ? (
            <Box w="full">
              <UserGreeting />
              <NavMenu logout={logout} />
            </Box>
          ) : (
            <VStack gap="4" w="full">
              <IssuerInfo offering={offering} isLargeScreen />
              <WorkflowProgress offering={offering} position={position} />
              {isAdditionalSignerPage && (
                <Box w="full">
                  <NavMenu
                    logout={logout}
                    menu={menu}
                    isAdditionalSignerPage={isAdditionalSignerPage}
                  />
                </Box>
              )}
            </VStack>
          )}
          <Box h="1em" /> {/* placeholder element */}
        </>
      )}
      {/* <Icon.HelpCircle size="2em" cursor="pointer" className="intercom" /> */}
    </Flex>
  );
};
