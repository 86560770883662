import { ApiClient } from "./ApiClient";

class AuthClient extends ApiClient {
  async register(
    data: Record<string, any>,
    type: string = "offering",
    token: string
  ) {
    const response = await this.post(`/v2/auth/register/${type}`, data, {
      params: { token },
    });
    return response.data;
  }

  async mfa(data: Record<string, any>) {
    const response = await this.post("/v2/auth/mfa", data);
    return response.data;
  }

  async login(data: Record<string, any>) {
    const response = await this.post("/v2/auth/login", data);
    return response.data;
  }

  async workflowRegister(data: Record<string, any>, offeringId: string) {
    const response = await this.post(
      `/v2/auth/workflow-register/${offeringId}`,
      data
    );
    return response;
  }
}

export default AuthClient;
