import { EXEMPTION_TYPES, STATUS } from "../constants/offerings";

export const offeringIsRegD = (offering) => {
  return [
    EXEMPTION_TYPES.REG_D_506_B,
    EXEMPTION_TYPES.REG_D_506_C,
    EXEMPTION_TYPES.REG_D_504_B,
  ].includes(offering?.exemption_type);
};

export const offeringIsRegA = (offering) => {
  return offering?.exemption_type === EXEMPTION_TYPES.REG_A;
};

export const offeringIsRegAPlus = (offering) => {
  return offering?.exemption_type === EXEMPTION_TYPES.REG_A_PLUS;
};

export const offeringIsTypeRegA = (offering) => {
  return offeringIsRegA(offering) || offeringIsRegAPlus(offering);
};

export const offeringIsRule506c = (offering) => {
  return offering?.exemption_type === EXEMPTION_TYPES.REG_D_506_C;
};

export const offeringIsRule506b = (offering) => {
  return offering?.exemption_type === EXEMPTION_TYPES.REG_D_506_B;
};

export const offeringIsActive = (offering) => {
  return offering?.status === STATUS.ACTIVE;
};

export const offeringIsTerminated = (offering) => {
  return offering?.status === STATUS.TERMINATED;
};

export const offeringIsClosed = (offering) => {
  return offering?.status === STATUS.CLOSED;
};

export const offeringIsCompleted = (offering) => {
  return offeringIsClosed(offering) || offeringIsTerminated(offering);
};
