import { Center, Stack, VStack } from "@chakra-ui/react";
import React from "react";
import { useRouteError } from "react-router";
import NavBar from "../../components/nav/NavBar";
import { Heading, Text } from "@equidefi/ui";

function WorkflowRootError() {
  const error = useRouteError();
  let message = "Something went wrong trying to load this offering.";

  if (error?.toJSON?.()?.message === "Network Error") {
    message =
      "Please check your internet connection, a network error occurred.";
  }

  return (
    <Stack
      direction={{ base: "column", lg: "row" }}
      as="main"
      bgColor="background"
      h="100vh"
    >
      <NavBar>
        <Center w="full" h={{ base: "full", lg: "100vh" }}>
          <VStack align="start" justify="center">
            <Heading
              fontWeight="semibold"
              fontSize="4em"
              mb={{ base: "4", lg: "10" }}
            >
              {":("}
            </Heading>
            <Heading textStyle="h1" fontWeight="bold">
              Something's not right...
            </Heading>
            <Text mb="10">{message}</Text>
          </VStack>
        </Center>
      </NavBar>
    </Stack>
  );
}

export default WorkflowRootError;
