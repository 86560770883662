import { useContext, useEffect } from "react";
import Confetti from "react-confetti";
import { createPortal } from "react-dom";
import { Link } from "react-router";

import { Button } from "@chakra-ui/react";
import { Icon } from "@equidefi/ui/icon";

import useWorkflowPosition from "../../../hooks/useWorkflowPosition";
import { WorkflowContext } from "../context";

const WorkflowCompleted = () => {
  useWorkflowPosition(8);
  const { analytics } = useContext(WorkflowContext);

  useEffect(() => {
    analytics.track("complete");

    window.scrollTo(0, 0);

    /*const effect = async () => {
      const stripe = await stripePromise;

      const clientSecret = new URLSearchParams(window.location.search).get(
        "payment_intent_client_secret"
      );

      if (!clientSecret) return;

      const res = await stripe.retrievePaymentIntent(clientSecret);
    };

    effect();*/
  }, []);

  return (
    <>
      <div className="container mt-5">
        <div className="row vh-100 justify-content-center">
          <div className="col-12">
            <h1 className="text-center text-md-start">
              Thank you for completing your investment!
            </h1>
            <h3>
              Please continue to your document vault by clicking below. Your
              document vault is updated dynamically with information about the
              offering and your documents.
            </h3>
            <hr />
            <p>
              Your vault will be open through the entirety of the offering and
              beyond. You can always view or retrieve your documents from here
              and at any time!
            </p>

            <p>
              Should we need to reach out to you for any further information, we
              will do so. If you need to reach us, please contact us at
              support@equidefi.com.
            </p>
            <p>Thank you again for supporting the company!</p>
            <p>EquiDeFi Support</p>

            <Button
              as={Link}
              size="lg"
              to="/vault/dashboard"
              _hover={{ color: "white", textDecor: "none" }}
              rightIcon={<Icon.ArrowRightCircle size="1em" />}
              width={{ base: "full", md: "auto" }}
            >
              Go to My Vault
            </Button>
          </div>
          <div className="col-12 col-md-7 col-lg-6 col-xl-8 d-none d-lg-block">
            <div
              className="bg-cover h-100 min-vh-100 mt-n1 me-n3"
              style={{ backgroundImage: "url(img/login.jpg)" }}
            ></div>
          </div>
        </div>
      </div>
      <div className="d-print-none">
        {createPortal(<Confetti />, document.body)}
      </div>
    </>
  );
};

export default WorkflowCompleted;
