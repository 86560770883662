import { useFormik } from "formik";
import { Link, useLocation, useNavigate } from "react-router";
import * as Yup from "yup";

import { Button, Center, useToast } from "@chakra-ui/react";
import { useSendResetPasswordEmail } from "@equidefi/portals/hooks/useUsers";
import { Heading, Input, Text } from "@equidefi/ui";

import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import LoginContainer from "./LoginContainer";

const initialValues = {
  email: "",
};

const forgotSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email")
    .required("Email is required."),
});

const Forgot = (props) => {
  useDocumentTitle("Forgot Password");
  let { state } = useLocation();
  const redirectUrl = state?.redirectUrl || "/";

  const navigate = useNavigate();
  const toast = useToast();

  const resetPassword = useSendResetPasswordEmail();

  const onSubmit = async ({ email }, { resetForm }) => {
    try {
      await resetPassword.mutateAsync({
        email,
        source: "investor",
        redirect_to: redirectUrl,
      });

      toast({
        status: "info",
        description: `An email as been sent to ${email} with instructions to reset your password.`,
      });

      navigate(redirectUrl);
    } catch (error) {
      console.error(error);
      toast({
        status: "error",
        description: error?.response?.data?.errors?.join(". "),
      });
    } finally {
      resetForm();
    }
  };

  const { handleSubmit, errors, touched, getFieldProps, isValid } = useFormik({
    initialValues,
    validationSchema: forgotSchema,
    onSubmit,
    validateOnMount: true,
  });

  return (
    <LoginContainer>
      <form noValidate style={{ width: "100%" }} onSubmit={handleSubmit}>
        <Heading color="white" fontWeight="bold" m="0" textStyle="h3">
          Forgot Password?
        </Heading>
        <Text color="white" mb="5" textStyle="body2">
          Enter your email to get a password reset link.
        </Text>
        <Input
          error={errors.email}
          isInvalid={errors.email && touched.email}
          placeholder="Email Address"
          {...getFieldProps("email")}
          mb="5"
          h="12"
        />

        <Button
          isLoading={resetPassword.isLoading}
          isDisabled={!isValid}
          type="submit"
          h="12"
          w="full"
        >
          Continue
        </Button>

        <Center mt="5">
          <Text
            fontSize="sm"
            color="gray.400"
            mb="0"
            as={Link}
            _hover={{ color: "gray.500" }}
            to={redirectUrl}
          >
            Remember your password? Log in
          </Text>
        </Center>
      </form>
    </LoginContainer>
  );
};

export default Forgot;
