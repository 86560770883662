import { ApiClient } from "./ApiClient";

type ManualPaymentData = {
  investment_id: string;
  amount: number | string;
  reference_number: string;
  description?: string;
};

type RefundManualPayment = {
  investment_id: string;
  payment_id: string;
  reference_number?: string;
  notes: string;
}

type SetupPaymentData = {
  investment_id: string;
  category: "MANUAL";
  payment_type: "wire" | "check";
}

class PaymentClient extends ApiClient {
  async setupManualPayment(data: SetupPaymentData) {
    const response = await this.post(`/v2/payments/manual/setup`, data);
    return response.data;
  }

  async addManual(data: ManualPaymentData) {
    const response = await this.post(`/v2/payments/manual`, data);
    return response.data;
  }

  async refundManualPayemnt(data: RefundManualPayment) {
    const response = await this.put("/v2/payments/manual/refund", data);

    return response.data;
  }

  async confirmManualPayment(data: RefundManualPayment) {
    const response = await this.put("/v2/payments/manual/confirm", data);

    return response.data;
  }

  async cancelManualPayment(data: RefundManualPayment) {
    const { investment_id, payment_id, ...rest } = data;
    const response = await this.put(
      `/v2/payments/${investment_id}/${payment_id}/cancel`,
      {
        ...rest,
      }
    );

    return response.data;
  }

  async addManualRefund(data: ManualPaymentData) {
    const response = await this.post("/v2/payments/manual/refund", data);

    return response.data;
  }
}

export default PaymentClient;
