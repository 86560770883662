import { Box, Button, ButtonGroup } from "@chakra-ui/react";
import React from "react";
import { useNavigate, useParams } from "react-router";

import { PAYMENTS_CHANGE_METHOD_STATUSES } from "../../../../constants/payments";
import { PaymentRow } from "./PaymentRow";

const PaymentsCard = ({ payments = [], setAccordionIndex }) => {
  const navigate = useNavigate();
  const { id: investmentId } = useParams();

  const latestPayment = payments?.at(-1);

  return (
    <Box overflow="scroll" w="full">
      <table className="table table-hover">
        <thead>
          <tr>
            <th>Type</th>
            <th>Date</th>
            <th>Status</th>
            <Box as="th" textAlign="end">
              Amount
            </Box>
          </tr>
        </thead>
        <tbody>
          {payments.map((payment) => (
            <PaymentRow
              investmentId={investmentId}
              payment={payment}
              key={payment.id}
            />
          ))}
        </tbody>
      </table>
      <ButtonGroup w="full" orientation="vertical" gap="1">
        {latestPayment?.category === "MANUAL" &&
          latestPayment?.status === "created" && (
            <Button
              mt="2"
              w="full"
              variant="secondary"
              onClick={() => {
                setAccordionIndex(4);
                document
                  .getElementById("manual_payment_instructions")
                  .scrollIntoView();
              }}
            >
              Manual Payment Instructions
            </Button>
          )}
        {PAYMENTS_CHANGE_METHOD_STATUSES.includes(latestPayment?.status) && (
          <Button
            w="full"
            onClick={() =>
              navigate(`/vault/investment/${investmentId}/payment`)
            }
          >
            {latestPayment?.category === "MANUAL"
              ? "Change Payment Method"
              : "Retry payment"}
          </Button>
        )}
      </ButtonGroup>
    </Box>
  );
};

export default PaymentsCard;
