import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { ApiInstance } from "../clients/api";
import { STOCK_TYPE_OPTIONS } from "../pages/offering/constants";
import { useAuth } from "@equidefi/portals/hooks/useAuth";

export const refreshToken = () => {
  // @TODO move referesh token functionality to useAuth hook
  const { setToken } = useAuth();

  return new Promise(function (resolve, reject) {
    ApiInstance.post("auth/refresh")
      .then((response) => {
        setToken(response.data.token);
        resolve(response.data);
      })
      .catch((error) => reject(error));
  });
};

export const getUser = () => {
  return new Promise(function (resolve, reject) {
    ApiInstance.get("me")
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

export const updateUser = (request) => {
  return new Promise(function (resolve, reject) {
    ApiInstance.post("me", request)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

export const updatePassword = (id, password, token) => {
  return new Promise(function (resolve, reject) {
    ApiInstance.post(
      `user/${id}/password`,
      {
        password: password,
      },
      { headers: { Authorization: `Bearer ${token}` } }
    )
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};
//#endregion

//#region Offering

export const getOfferings = async (search) => {
  const response = await ApiInstance.get("/offering", { params: { search } });
  return response.data;
};

export const getOfferingById = (id) => {
  return new Promise(function (resolve, reject) {
    ApiInstance.get(`/offering/${id}`)
      .then((response) => {
        let offering = response.data;
        offering.unit_price = offering.unit_price
          ? Number(offering.unit_price?.replace(/[^0-9.-]+/g, "")).toFixed(2)
          : 0;
        offering.total_amount = Number(
          offering.total_amount?.replace(/[^0-9.-]+/g, "")
        );
        offering.minimum_offering_amount =
          offering.minimum_units * offering.unit_price;
        offering.optional_maximum_percent = Number(
          (offering.extension_units * 100) / offering.total_units
        );
        offering.investor_minimum_amount =
          offering.investor_minimum_units * offering.unit_price;
        resolve(offering);
      })
      .catch((error) => reject(error));
  });
};

export const getOfferingByIssuerId = (issuerId) => {
  return new Promise(function (resolve, reject) {
    ApiInstance.get(`/offering`, { params: { issuer_id: issuerId } })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

export const createOffering = (data) => {
  return new Promise(function (resolve, reject) {
    ApiInstance.post("/offering", data)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

export const updateOffering = (id, data) => {
  return new Promise(function (resolve, reject) {
    ApiInstance.post(`/offering/${id}`, data)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

export const getSettingsByOfferingId = (offeringId) => {
  return new Promise(function (resolve, reject) {
    ApiInstance.get(`/offering/${offeringId}/setting`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

export const getSettingById = (settings, settingId) => {
  let setting = settings.filter((item) => item.id === settingId);
  let value = setting.length > 0 ? setting[0].value : "";
  return value;
};

export const updateSettingById = (offeringId, settingId, value) => {
  return new Promise(function (resolve, reject) {
    ApiInstance.post(`/offering/${offeringId}/setting/${settingId}`, {
      value: value,
    })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

export const createOfferingContact = (offeringId, contact) => {
  return new Promise(function (resolve, reject) {
    ApiInstance.post(`/offering/${offeringId}/contact`, contact)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

export const updateOfferingContact = (offeringId, contactId, contact) => {
  return new Promise(function (resolve, reject) {
    ApiInstance.post(`/offering/${offeringId}/contact/${contactId}`, contact)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

export const deleteOfferingContact = (offeringId, contactId) => {
  return new Promise(function (resolve, reject) {
    ApiInstance.delete(`/offering/${offeringId}/contact/${contactId}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

//#endregion

//#region Investment

export const getInvestmentById = (id) => {
  return new Promise(function (resolve, reject) {
    ApiInstance.get(`/investment/${id}`)
      .then((response) => {
        let investment = response.data;

        investment.amount = Number(investment.amount.replace(/[^0-9.-]+/g, ""));
        investment.approved_amount = investment.approved_amount
          ? Number(investment.approved_amount.replace(/[^0-9.-]+/g, ""))
          : 0;

        resolve(investment);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const getOfferingInvestments = async (offeringId) => {
  const response = await ApiInstance.get(`/offering/${offeringId}/investment`);
  return response.data;
};

export const createInvestment = (issuerId, offeringId, userId) => {
  return new Promise(function (resolve, reject) {
    ApiInstance.post("investment", {
      issuer_id: issuerId,
      offering_id: offeringId,
      investor_id: userId,
    })
      .then((response) => {
        ApiInstance.post(`investment/${response.data.id}/status/invite_sent`)
          .then((response) => resolve())
          .catch((error) => reject(error));
      })
      .catch((error) => reject(error));
  });
};

//#endregion

//#region User
export const createUser = (user) => {
  return new Promise(function (resolve, reject) {
    ApiInstance.post("/user", user)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

export const getUserByEmail = (email) => {
  return new Promise(function (resolve, reject) {
    ApiInstance.get(`/user/${email}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

export const getUsersByIssuerId = (issuerId) => {
  return new Promise(function (resolve, reject) {
    ApiInstance.get(`/user`, { params: { issuer_id: issuerId } })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

export const updateIssuerUserRole = (userId, issuerId, role) => {
  return new Promise(function (resolve, reject) {
    ApiInstance.post(`/user/${userId}/role`, {
      issuer_id: issuerId,
      role: role,
    })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

export const removeUserFromOffering = (userId, offeringId) => {
  return new Promise(function (resolve, reject) {
    ApiInstance.delete(`/user/${userId}/offering/${offeringId}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

export const updateOfferingUserRole = (userId, offeringId, role) => {
  return new Promise(function (resolve, reject) {
    ApiInstance.post(`/user/${userId}/role`, {
      offering_id: offeringId,
      role: role,
    })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

export const removeIssuerUser = (userId, issuerId) => {
  return new Promise(function (resolve, reject) {
    ApiInstance.delete(`/user/${userId}/issuer/${issuerId}`, {
      issuer_id: issuerId,
    })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

export const createOfferingDocument = (offeringId, document) => {
  return new Promise(function (resolve, reject) {
    ApiInstance.post(`/offering/${offeringId}/document`, document)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

export const deleteOfferingDocument = (offeringId, documentId) => {
  return new Promise(function (resolve, reject) {
    ApiInstance.delete(`/offering/${offeringId}/document/${documentId}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

export const createOfferingAgreement = (offeringId, agreement) => {
  return new Promise(function (resolve, reject) {
    ApiInstance.post(`/offering/${offeringId}/agreement`, agreement)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

export const updateOfferingAgreement = (offeringId, agreementId, agreement) => {
  return new Promise(function (resolve, reject) {
    ApiInstance.post(
      `/offering/${offeringId}/agreement/${agreementId}`,
      agreement
    )
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

export const deleteOfferingAgreement = (offeringId, agreementId) => {
  return new Promise(function (resolve, reject) {
    ApiInstance.delete(`/offering/${offeringId}/agreement/${agreementId}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

export const stockTypeName = (stockType) => {
  let value = "";
  let items = STOCK_TYPE_OPTIONS.filter((x) => x.value === stockType);
  if (items.length > 0) {
    value = items[0].label;
    return value;
  } else {
    return stockType;
  }
};

export const stockTypeOfferingPage = (stockType) => {
  let value = "";
  let items = STOCK_TYPE_OPTIONS.filter((x) => x.value === stockType);

  if (items.length > 0) {
    value = items[0].label;
    return value;
  } else {
    const common_stock = stockType?.split(" ").includes("common_stock");
    const common_stock2 = stockType?.split(" ").includes("Common");
    const preferred_stock = stockType?.split(" ").includes("preferred_stock");
    const preferred_stock2 = stockType?.split(" ").includes("Preferred");
    const safe = stockType?.split(" ").includes("Safe");
    const unit = stockType?.split(" ").includes("Unit");
    const in_lieu_of_cash = stockType?.split(" ")?.includes("lieu");

    if (safe) {
      return "SAFE";
    } else if (unit) {
      return "Unit";
    } else if (common_stock || common_stock2) {
      return "Common Stock";
    } else if (preferred_stock || preferred_stock2) {
      return "Preferred Stock";
    } else if (in_lieu_of_cash) {
      return "In Lieu of Cash";
    }
  }
};

export const isOfferingOwner = (user, offeringId) => {
  return user?.roles?.offering?.[offeringId] === "owner";
};

export const isIssuerOwner = (user, issuerId) => {
  return user?.roles?.issuer?.[issuerId] === "owner";
};

export const isIssuerEditor = (user, issuerId) => {
  return user?.roles?.issuer?.[issuerId] === "editor";
};

export const isIssuerViewer = (user, issuerId) => {
  return user?.roles?.issuer?.[issuerId] === "viewer";
};

export const isAnyIssuerOwner = (user) => {
  const values = Object.values(user?.roles?.issuer ?? {});
  return values.includes("owner");
};

export const isAnyIssuerEditor = (user) => {
  const values = Object.values(user?.roles?.issuer ?? {});
  return values.includes("editor");
};

export const isOfferingEditor = (user, offeringId) => {
  return user?.roles?.offering?.[offeringId] === "editor";
};

export const isOfferingViewer = (user, offeringId) => {
  return user?.roles?.offering?.[offeringId] === "viewer";
};

export const isAdmin = (user) => {
  return user?.roles?.admin === true;
};

export function IconControl(prop) {
  const formGroupStyle = prop.disabled && "text-muted";
  return (
    <div>
      <label className={formGroupStyle}>{prop.inputTitle}</label>
      <span className="text-danger">{prop.required}</span>
      <span>
        <OverlayTrigger
          overlay={(props) => (
            <Tooltip
              {...props}
              className={
                prop.tooltipTitle.length > 20 ? "multipleLine" : "OneLine"
              }
            >
              {prop.tooltipTitle}
            </Tooltip>
          )}
          placement={prop.placement}
        >
          {prop.button !== "true" ? (
            <span className={formGroupStyle}>
              <i className={prop.iconName}></i>
            </span>
          ) : (
            <span data-tip="Lorem ipsum" data-tip-disable={false}>
              <button className={"btn btn-eq-primary"} disabled={true}>
                {prop.buttonName}
              </button>
            </span>
          )}
        </OverlayTrigger>
      </span>
    </div>
  );
}
