import { HStack } from "@chakra-ui/react";
import { offeringIsTypeRegA } from "@equidefi/shared";
import { Heading, Paper, Text } from "@equidefi/ui";
import { useState } from "react";
import { InvestmentClosed } from "./Closed";
import { investmentStepsCopy, stepsRegA, stepsRegD } from "./investmentSteps";
import { InvesmtentProgressItem } from "./ProgressItem";
import { InvestmentRejected } from "./Rejected";

const InvestmentProgress = ({ investment, offering }) => {
  const [activeStep, setActiveStep] = useState(investment?.next);

  if (!offering || !investment) return null;

  if (investment?.status === "CLOSED") return <InvestmentClosed />;
  if (investment?.status === "REJECTED") return <InvestmentRejected />;

  const steps = offeringIsTypeRegA(offering) ? stepsRegA : stepsRegD;

  const stepsList = steps.map((step) => {
    return (
      <InvesmtentProgressItem
        key={step}
        next={investment?.next}
        step={step}
        active={activeStep === step}
        onClick={() => setActiveStep(step)}
        steps={steps}
      />
    );
  });

  return (
    <Paper mt="4" p="4" boxShadow="card">
      <Text opacity="70%" textTransform="uppercase" mb="5">
        Investment Progress
      </Text>
      <Heading fontWeight="bold" m="0">
        {investmentStepsCopy[activeStep]?.heading}
      </Heading>
      <Text mb="5">{investmentStepsCopy[activeStep]?.text}</Text>

      <HStack gap={{ base: 1, sm: 2 }} minW="full">
        {stepsList}
      </HStack>
    </Paper>
  );
};

export default InvestmentProgress;
