import { Button } from "@chakra-ui/react";
import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";
import { offeringIsCompleted } from "@equidefi/shared";
import { Link } from "react-router";

const Continue = ({ investment, offering }) => {
  const { data: user } = useCurrentUser();

  if (!investment || !offering || !investment.next) {
    return null;
  }

  if (investment.status !== "PENDING") {
    return null;
  }

  if (investment?.user?.id !== user?.id) {
    return null;
  }

  if (offeringIsCompleted(offering)) {
    return null;
  }

  localStorage.removeItem("eq-investor");

  return (
    <Button
      onClick={(e) => {
        e.stopPropagation();
      }}
      w="full"
      as={Link}
      h="12"
      to={`/offerings/${offering.slug}/${
        investment.id
      }/${investment.next.toLowerCase()}`}
    >
      Resume Investment
    </Button>
  );
};

export default Continue;
