import { Flex, Stack } from "@chakra-ui/react";
import { Outlet } from "react-router";
import NavBar from "../components/nav/NavBar";

export default function SupportPageLayout() {
  return (
    <Stack
      as="main"
      bgColor="background"
      direction={{ base: "column", lg: "row" }}
    >
      <NavBar>
        <Flex
          direction="column"
          align="center"
          justify={{ base: "start", lg: "center" }}
          w="full"
          minH="100vh"
        >
          <Outlet />
        </Flex>
      </NavBar>
    </Stack>
  );
}
